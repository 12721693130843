import { Box, Typography } from '@mui/material'
import React from 'react'
import './hero-services.css'
const HeroSrvices = () => {
    return (
        <>
            <div className="hero-services">
                <div className="over-lay"></div>
                <Box sx={{
                    zIndex: 99, display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mx: { md: 4, xs: 1 },
                    gap: 1,
                    width: { md: '80%', xs: '85%' },
                    mt: '-8%'
                }} >
                    <Typography sx={{ fontSize: { md: '2.5rem', xs: '1.5rem' }, fontWeight: 'bold', textAlign: { md: 'inherit', xs: 'center' }, }} color={'#fff'} >Our Comprehensive Marketing Services</Typography>
                    <Box>
                        <Typography sx={{ color: '#FFF', textAlign: 'center' }} variant='h6' >
                            <span style={{ color: ' #6129E0', fontWeight: 'bold' }} >STASH</span> is your partner in your brand transformation and growth journey. We offer a variety of marketing services, each designed to elevate your brand and leave a lasting impact in your industry.
                        </Typography>
                    </Box>
                </Box>
            </div>
        </>
    )
}

export default HeroSrvices