import React from 'react'
import HeroAbout from '../components/heroAbout/HeroAbout'
import { Box } from '@mui/material'
import Section from '../components/global/Section'
import './about-us.css'
const About = () => {
    return (
        <>
            <div style={{ backgroundColor: '#101010' }} >
                <HeroAbout />
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '25px',
                    p: { md: 2, xs: 2 },
                    justifyContent: 'center',
                    mt: 1,
                    mx: { md: 6, xs: 2 },
                    zIndex: 999, position: 'relative'
                }} >
                    <div className="bg-about"></div>
                    <Section title={`Our mission`} description={`We believe that every brand has a story waiting to be told, and our mission is to be the authors of those stories. With a commitment to integrity that shows through every interaction, we build trust, foster authenticity, and ensure your brand voice resonates with clarity and authenticity.`} />
                    <Section title={`Our vision`} description={`Our vision at Stash is to lead the marketing industry, earning recognition for our unwavering commitment to creativity, authenticity and results. We look beyond trends and dedicate ourselves to shaping the future of marketing.`} />
                    <Section title={`How can we help you`} description={`We understand the challenges businesses face in a rapidly evolving marketing landscape. Our team of experts offers a wide range of services designed to help you achieve your marketing goals. From creating engaging content to harnessing the power of social media, we provide comprehensive solutions that get results. Together, we will build a brand legacy that will stand the test of time.`} />
                    <Section title={`How we help clients`} description={`Our services are carefully designed to help clients gain a competitive advantage, reach their target audience, and achieve measurable results. We work closely with our clients as partners, not just service providers, to ensure their success in the ever-evolving marketing landscape.`} />
                </Box>
            </div>
        </>
    )
}

export default About