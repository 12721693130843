import React from 'react'
import HeroSrvices from '../components/services/HeroSrvices'
import ListServices from '../components/services/ListServices'

const Services = () => {
    return (
        <>
            <HeroSrvices />
            <ListServices />
        </>
    )
}

export default Services