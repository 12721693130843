import { Box } from '@mui/material'
import React from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
const Soutial = () => {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 4, alignItems: 'center', justifyContent: 'center' }} >
                <Box>
                    <a href="https://instagram.com/stash_mktg?igshid=MTNiYzNiMzkwZA==">
                        <InstagramIcon sx={{ color: '#ddd', height: '1.5em', width: '1.5em' }} />
                    </a>
                </Box>
                <Box>
                    <a href="https://www.linkedin.com/company/stashmarketing/">
                        <LinkedInIcon sx={{ color: '#ddd', height: '1.5em', width: '1.5em' }} />
                    </a>
                </Box>
                <Box>
                    <a href="http://test">
                        <FacebookIcon sx={{ color: '#ddd', height: '1.5em', width: '1.5em' }} />
                    </a>
                </Box>
            </Box>
        </>
    )
}

export default Soutial