import React from 'react'
import './HeroAbout.css'
import { Box, Typography } from '@mui/material'
const HeroAbout = () => {
    return (
        <>
            <div className="hero-about">
                {/* <div className="over-lay"></div> */}
                <Box sx={{ zIndex: 999, display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <Typography variant='h2' color={'#fff'} sx={{
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 1.18)",
                        p: '0 40px',
                        borderRadius: '10px',
                        transition: '0.5s',
                        ':hover': {
                            backgroundColor: 'rgb(255 255 255 / 10%)'
                        },
                    }} >About Us</Typography>
                    <Box sx={{
                        p: { md: '25px', xs: '15px' }, borderRadius: '18px',
                        background: 'rgba(43, 43, 43, 0.66)',
                        width: '90%', margin: 'auto',
                        position: 'absolute',
                        bottom: { md: '20px', xs: '-15px' }
                    }} >
                        <Typography sx={{ color: '#FFF', fontSize: { md: '1rem', xs: '1rem', textAlign: 'center' } }} >
                            In the fast-paced world of marketing, where innovation is the heartbeat, STASH emerges as a guiding light. We are more than just a marketing agency; we are the architects of your brand's destiny. Where we craft brand stories that don't just captivate audiences; they leave an indelible mark on hearts and minds.
                        </Typography>
                    </Box>
                </Box>
            </div>
        </>
    )
}

export default HeroAbout