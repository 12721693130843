import { Box, Button, CardMedia, Typography } from '@mui/material'
import React from 'react'
import homeSection from './../../assets/home/1.png'
import './about-home.css'
import { Link } from 'react-router-dom';
const AboutHome = () => {
    return (
        <div>
            <div className="bg-about-home">
                {/* <Container> */}
                <Box sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between', gap: '20px',
                    flexDirection: { md: 'row', xs: 'column-reverse' }
                }}>
                    <Box>
                        <Typography sx={{ color: ' #6129E0' }} variant='h3' >About Us</Typography>
                        <Typography sx={{ color: '#FFF', lineHeight: { md: '28px', xs: '28px' }, fontSize: '20px' }} >
                            In the fast-paced world of marketing, where innovation is the heartbeat, STASH emerges as a guiding light. We are more than just a marketing agency; we are the architects of your brand's destiny. Where we craft brand stories that don't just captivate audiences; they leave an indelible mark on hearts and minds.
                            <Box mt={1} >
                                <Link to={'/about-us'} style={{ color: '#FFF', textDecoration: 'none' }} >
                                    <Button variant="contained"
                                        type="button"
                                        className='my-btn'
                                        sx={{
                                            backgroundColor: ' #6129E0', ':hover': {
                                                backgroundColor: ' #6129E0',
                                                color: '#FFF'
                                            },
                                            color: "#FFF",
                                            fontWeight: 'bold',
                                            width: '150px',
                                            height: '48px',
                                            borderRadius: '10px',
                                            fontSize: '18px'
                                            // margin: 3
                                        }}>READ MORE...</Button></Link>
                            </Box>
                        </Typography>
                    </Box>
                    <Box>
                        <Box position={'relative'} >
                            <CardMedia component={'img'}
                                src={homeSection}
                                sx={{ width: { md: '400px', xs: '95%' }, height: '300px', margin: 'auto', objectFit: 'contain', zIndex: 9, position: 'relative' }}
                            />
                            <div className="home-section"></div>
                        </Box>
                    </Box>
                </Box>
                {/* </Container> */}
            </div>
        </div>
    )
}

export default AboutHome