import { Box, Button, Container } from '@mui/material'
import React, { useRef } from 'react'
import emailjs from '@emailjs/browser';
import './form.css'

const FormLogic = () => {
    const form = useRef()
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service', 'template', form.current, 'account',)
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset()
    }
    return (
        <>
            <div style={{ position: 'relative' }} >
                {/* <div className="bg-contact"></div> */}
                <Container>
                    <Box py={1} >
                        <Box component={'form'} ref={form} onSubmit={sendEmail} >
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                                alignItems: 'center',
                                justifyContent: 'center'
                            }} >
                                <input
                                    fullWidth
                                    style={{ backgroundColor: '#FFF' }}
                                    hiddenLabel
                                    required
                                    placeholder='Name'
                                    id="name"
                                    variant="filled"
                                    size="medium"
                                    name='name'
                                    className='input'
                                />
                                <input
                                    required
                                    fullWidth
                                    style={{ backgroundColor: '#FFF' }}
                                    hiddenLabel
                                    placeholder='E-mail'
                                    id="email"
                                    variant="filled"
                                    size="medium"
                                    name='email'
                                    className='input'
                                />
                                <input
                                    required
                                    fullWidth
                                    style={{ backgroundColor: '#FFF' }}
                                    hiddenLabel
                                    placeholder='Phone'
                                    id="phone"
                                    variant="filled"
                                    size="medium"
                                    name='phone'
                                    className='input'
                                />
                                <textarea
                                    required
                                    fullWidth
                                    style={{ backgroundColor: '#FFF', height: '100px', fontSize: '20px', padding: '15px', resize: 'none' }}
                                    hiddenLabel
                                    id="outlined-multiline-flexible"
                                    multiline
                                    rows={6}
                                    placeholder='Your Message'
                                    variant="filled"
                                    size="medium"
                                    name='message'
                                    className='input'
                                />
                                <Box>
                                    <Button variant="contained"
                                        type="submit"
                                        className='my-btn'
                                        sx={{
                                            backgroundColor: ' #6129E0', ':hover': {
                                                backgroundColor: ' #6129E0'
                                            },
                                            color: "#FFF",
                                            fontWeight: 'bold',
                                            width: '150px',
                                            height: '48px',
                                            borderRadius: '10px',
                                            fontSize: '18px'
                                            // margin: 3
                                        }}>Submit</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </div>
        </>
    )
}

export default FormLogic