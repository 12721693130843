import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import './hero.css'
import hero from './../../assets/hero/3.png'
const Hero = () => {
    return (
        <>
            <div className='hero' >
                <Box sx={{ display: 'flex', zIndex: 9999, alignItems: 'center', justifyContent: 'space-between', width: '100%', flexDirection: { md: 'row', xs: 'column-reverse' } }} >
                    <Box sx={{ zIndex: 999, textAlign: { md: 'left', xs: 'center' }, color: '#FFF', }} >
                        <Typography variant='h4' >Welcome to <span style={{ color: ' #6129E0', fontWeight: 'bold' }} >STASH </span> </Typography>
                        <Typography variant='h4' >The Architects of Your Brand's Destiny</Typography>
                    </Box>
                    <Box sx={{ position: 'relative' }} >
                        <CardMedia component={'img'}
                            src={hero}
                            sx={{ height: { md: '250px', xs: '250px' }, borderRadius: '15px', objectFit: 'fill' }}
                        />
                        {/* <div className="over-lay-img"></div> */}
                    </Box>
                </Box>
            </div>
        </>
    )
}

export default Hero