import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import { IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import NavDrawer from "./NavDrawer";
import logo from './../../assets/logo/logo.png';
import './Nav.css'
// import CustomizedMenus from "./CustomizedMenus";


function NavBar() {
    const [active, setActive] = useState(false);
    const [drawer, setDrawer] = useState(false);
    const isActive = () => {
        window.scrollY > 0 ? setActive(true) : setActive(false);
    };

    useEffect(() => {
        window.addEventListener("scroll", isActive);
        return () => {
            window.removeEventListener("scroll", isActive);
        };
    }, []);
    return (
        <>
            <AppBar position="static" elevation={0}
                sx={active ? { background: '#000' } : { background: 'transparent' }
                }
                style={{
                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.18)",
                    transition: '0.3s all ease-in-out',
                    padding: "10px",
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 999999,

                }}>
                <>
                    <Toolbar disableGutters sx={{
                    }} >
                        <Box
                            sx={{
                                flexGrow: 1,
                                gap: 4,
                                marginInlineEnd: 1,
                                display: { md: "flex", xs: "none" },
                                alignItems: "center",
                                px: 6,

                            }}>
                            <Link
                                className="link"
                                to="/"
                                style={{
                                    width: '18%',
                                    textDecoration: "none",
                                    marginInlineEnd: '5%',
                                    display: 'flex'
                                }}>
                                <img src={logo} alt="logo" className="nav-logo" width={'100px'} ></img>
                            </Link>
                            <Box sx={{
                                flexGrow: 1,
                                gap: 10,
                                marginInlineStart: 8,
                                display: { md: "flex", xs: "none" },
                                alignItems: "center",
                                justifyContent: 'flex-end',
                            }}>

                                <Link
                                    className="link"
                                    to="/"
                                    style={{

                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}>
                                    Home
                                </Link>
                                <Link
                                    className="link"
                                    to="/about-us"
                                    style={{

                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}>
                                    About Us
                                </Link>
                                <Link
                                    className="link"
                                    to="/services"
                                    style={{

                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}>
                                    Our Services
                                </Link>
                                <Link
                                    className="link"
                                    to="/contact-us"
                                    style={{

                                        textDecoration: "none",
                                        fontSize: 18,
                                        fontWeight: "bold",
                                    }}>
                                    Contact Us
                                </Link>
                            </Box>
                        </Box>
                        <Box sx={{
                            justifyContent: 'end', display: 'flex', gap: { md: '65px', xs: 1 },
                            alignItems: 'center',
                            width: { md: '0', xs: '100%' }
                        }} >
                            <Box
                                sx={{
                                    flexGrow: 0,
                                    display: { md: "none", xs: "flex" },
                                    justifyContent: "space-between",
                                    width: '100%'
                                }}>
                                <IconButton onClick={() => setDrawer(true)}>
                                    <MenuIcon sx={{ fontSize: '2rem', color: '#fff' }} />
                                </IconButton>

                                <Link
                                    className="link"
                                    to="/"
                                    style={{

                                        textDecoration: "none",
                                        marginInlineEnd: '5%'
                                    }}>
                                    <img src={logo} style={{ height: '35px' }} alt="logo" className="nav-logo" ></img>
                                </Link>
                            </Box>
                        </Box>
                    </Toolbar>
                </>
            </AppBar>
            <NavDrawer drawer={drawer} setDrawer={setDrawer} />
        </>
    );
}
export default NavBar;
