import { Box, Typography } from '@mui/material'
import React from 'react'
import SliderLogic from '../SliderLogic/SliderLogic'
import { SwiperSlide } from 'swiper/react'
import { our_client } from './../../constants'

const OurClients = () => {
    return (
        <>
            <div>
                <Box>
                    <Typography textAlign={'center'} variant='h3' color={' #6129E0'} >
                        Our Clients
                    </Typography>
                    <SliderLogic>
                        {our_client.map((client) => (
                            <SwiperSlide>
                                <img src={client.imageUrl} alt="slide_image" />
                            </SwiperSlide>
                        ))}
                    </SliderLogic>
                </Box>
            </div>
        </>
    )
}

export default OurClients