import * as React from "react";
import Drawer from "@mui/material/Drawer";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
// import { products } from './../../constants'
export default function NavDrawer({ setDrawer, drawer }) {
    return (
        <div style={{ position: 'relative', zIndex: 99999999999999 }} >
            <React.Fragment>
                <Drawer
                    anchor="left"
                    open={drawer}
                    onClose={() => setDrawer(false)}
                    style={{ position: 'relative', zIndex: 99999999999999, }}
                >
                    <Box
                        sx={{
                            width: 300,
                            gap: 3,
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "right",
                            padding: "40px 20px",
                            background: 'linear-gradient(360deg, rgba(102,102,102,1) 12%, rgba(96,42,225,1) 47%, rgba(0,0,0,1) 100%)',
                            height: '100%',
                        }}
                        style={{ position: 'relative', zIndex: 99999999999999 }} >
                        <Link
                            to="/"
                            style={{
                                color: "#fff",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            Home
                        </Link>
                        <Link
                            to="/services"
                            style={{
                                color: "#fff",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }}
                            onClick={() => setDrawer(false)}>
                            Our Services
                        </Link>
                        <Link
                            to="/about-us"
                            style={{
                                color: "#fff",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }} onClick={() => setDrawer(false)}>
                            About Us
                        </Link>
                        <Link
                            to="/contact-us"
                            style={{
                                color: "#fff",
                                textDecoration: "none",
                                fontSize: 18,
                                fontWeight: "bold",
                            }} onClick={() => setDrawer(false)}>
                            Contact Us
                        </Link>
                    </Box>
                </Drawer>
            </React.Fragment>
        </div>
    );
}
