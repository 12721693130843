import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import FormHome from './FormHome'
import './form-home.css'

const ContactHome = () => {
    return (
        <>
            <Box position={'relative'} >
                <div className="bg-contact-home"></div>
                <Container>
                    <Grid container spacing={2} sx={{ position: 'relative', zIndex: 99, alignItems: 'center', flexDirection: { md: 'row', xs: 'column-reverse' } }} >
                        <Grid item md={6} xs={12}>
                            <FormHome />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Box sx={{
                                zIndex: 999,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                margin: 'auto',
                            }} >
                                <Typography sx={{
                                    color: ' #6129E0', textAlign: 'center',
                                    fontSize: { md: '3rem', xs: '2rem' }
                                }} variant='h3' >Connect with STASH</Typography>
                                <Typography sx={{
                                    color: '#FFF',
                                    fontWeight: 'bold',
                                    fontSize: { md: '1.6rem', xs: '1rem' },
                                    display: 'flex',
                                    alignItems: 'center', gap: '10px'
                                }} ><span>Tel:</span><span> +20111959826</span> || <span>+447429725744</span></Typography>
                                <Typography sx={{
                                    color: '#73F7CF',
                                    fontWeight: 'bold',
                                    fontSize: { md: '1.5rem', xs: '1rem' }
                                }} >Email: sales@stashmarketing.com</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
}

export default ContactHome