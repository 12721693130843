import { Box, Typography } from '@mui/material'
import React from 'react'

const Section = ({ title, description }) => {
    return (
        <>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }} >
                <Typography variant='h3' sx={{ color: ' #6129E0', textAlign: 'center', fontSize: { md: '2.5rem', xs: '1.8rem' } }} >{title}</Typography>
                <Typography sx={{ color: '#FFF', fontSize: '18px', fontWeight: 'bold', }} >{description}</Typography>
            </Box>
        </>
    )
}

export default Section