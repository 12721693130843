import React from 'react'
import Card from '../global/Card'
import { Box, Grid } from '@mui/material'
import { services } from './../../constants'
import './services.css'
const ListServices = () => {
    return (
        <Box sx={{ mt: '-10%', zIndex: 9999, position: 'relative', p: 4 }} >
            <Grid container spacing={4}>
                {services.map((service) => (
                    <Grid item md={3} xs={12}>
                        <Card imageUrl={service.imageUrl} title={service.title} description={service.description} />
                    </Grid>
                ))}
                <div className="bg-services"></div>
            </Grid>
        </Box>
    )
}

export default ListServices