import React from 'react'
import Hero from '../components/hero/Hero'
import AboutHome from '../components/homeAbout/AboutHome'
import OurServices from '../components/our-services/OurServices'
import ContactHome from '../components/contact-home/ContactHome'
import OurClients from '../components/our-client/OurClients'

const Home = () => {
    return (
        <div>
            <Hero />
            <AboutHome />
            <OurServices />
            <OurClients />
            <ContactHome />
        </div>
    )
}

export default Home