import { Box, Button, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import { servicesHome } from './../../constants'
import Card from '../global/Card'
import { Link } from 'react-router-dom'

const OurServices = () => {
    return (
        <>
            <div style={{ padding: '30px 0' }}>
                <Container>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', mx: 1 }} >
                        <Typography variant="h3" sx={{ color: ' #6129E0', fontSize: { md: '2.5rem', xs: '2rem' }, m: '2px' }} >Our Comprehensive Marketing Services</Typography>
                        <Typography sx={{ color: '#FFF', fontSize: '16px' }} > <span style={{ color: ' #6129E0', fontWeight: 'bold' }} >STASH</span> is your partner in your brand transformation and growth journey. We offer a variety of marketing services, each designed to elevate your brand and leave a lasting impact in your industry.</Typography>
                        <Grid container spacing={2} sx={{ position: 'relative', zIndex: 99, pb: 1, }} >
                            {servicesHome.map((service) => (
                                <Grid item md={3} xs={12}>
                                    <Card imageUrl={service.imageUrl} title={service.title} description={service.description} />
                                </Grid>
                            ))}
                            <Grid item md={12} xs={12}>
                                <Box>
                                    <Link to={'/services'} style={{ color: '#FFF', textDecoration: 'none' }} >
                                        <Button variant="contained"
                                            type="submit"
                                            className='my-btn'
                                            sx={{
                                                backgroundColor: ' #6129E0', ':hover': {
                                                    backgroundColor: ' #6129E0',
                                                    color: '#FFF'
                                                },
                                                color: "#FFF",
                                                fontWeight: 'bold',
                                                width: '150px',
                                                height: '48px',
                                                borderRadius: '10px',
                                                fontSize: '18px'
                                                // margin: 3
                                            }}>
                                            View All
                                        </Button>
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </div>
        </>
    )
}

export default OurServices