import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import logo from './../../assets/logo/logo.png';
import Soutial from './Soutial';

const Footer = () => {
    return (
        <>
            <Box sx={{ background: '#2B2B2B', textAlign: 'center', p: 4 }} >
                <Grid container spacing={2} alignItems={'center'} >
                    <Grid item md={4} xs={12}>
                        <img src={logo} alt="logo" className="nav-logo" ></img>
                    </Grid>
                    <Grid item md={4} xs={12}><Typography sx={{
                        color: '#FFF',
                        fontWeight: 'bold',
                        fontSize: '18px'
                    }} >Copyright @ 2023 .All Right Reserved</Typography></Grid>
                    <Grid item md={4} xs={12}>
                        <Soutial />
                    </Grid>
                </Grid>
            </Box >
        </>
    )
}

export default Footer