import React from 'react'
import HeroContact from '../components/contactUs/HeroContact'
// import FormLogic from '../components/contactUs/FormLogic'

const Contact = () => {
    return (
        <div>
            <HeroContact />

        </div>
    )
}

export default Contact