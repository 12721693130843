import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        mode: "light",
        primary: {
            main: "#ffffff",
        },
        secondary: {
            main: "#76232d",
        },
        background: {
            default: "#ffffff",
        },
    },
    typography: {
        allVariants: {
            typography: {
                fontFamily: 'Trois Mille TRIAL Bold, sans-serif',
                fontWeight: "bold",
            },
        },
    },
});

export default theme;
