import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import './card.css'
// import WbIncandescentOutlinedIcon from '@mui/icons-material/WbIncandescentOutlined';
const Card = ({ imageUrl, title, description }) => {
    return (
        <>
            <Box sx={{
                p: 2, backgroundColor: '#2B2B2B', boxShadow: '0px 0px 18px 0px rgba(255, 255, 255, 0.13)', borderRadius: '26px',
                height: '100%'
            }} className='boxAnimate' >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, }} >
                    <CardMedia component={'img'} className='animate' src={imageUrl} sx={{
                        width: '70px',
                    }} />
                    <Typography sx={{ fontWeight: 600, fontSize: '18px', color: ' #73F7CF' }} >{title}</Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: '12px', color: '#FFF' }}>{description}</Typography>
                </Box>
            </Box>
        </>
    )
}
export default Card