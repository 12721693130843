import client_1 from './../assets/LOGOSOURCLIENT/LOGOD FOR WEBSITE-01.png'
import client_2 from './../assets/LOGOSOURCLIENT/LOGOD FOR WEBSITE-02.png'
import client_3 from './../assets/LOGOSOURCLIENT/LOGOD FOR WEBSITE-03.png'
import client_4 from './../assets/LOGOSOURCLIENT/LOGOD FOR WEBSITE-04.png'
import client_5 from './../assets/LOGOSOURCLIENT/LOGOD FOR WEBSITE-05.png'
import client_6 from './../assets/LOGOSOURCLIENT/LOGOD FOR WEBSITE-06.png'
import client_7 from './../assets/LOGOSOURCLIENT/LOGOD FOR WEBSITE-07.png'
import client_8 from './../assets/LOGOSOURCLIENT/LOGOD FOR WEBSITE-08.png'
import client_9 from './../assets/LOGOSOURCLIENT/LOGOD FOR WEBSITE-11.png'
import client_10 from './../assets/LOGOSOURCLIENT/LOGOD FOR WEBSITE-12.png'
import icon_1 from './../assets/icon-service/1.svg'
import icon_2 from './../assets/icon-service/2.svg'
import icon_3 from './../assets/icon-service/3.svg'
import icon_4 from './../assets/icon-service/4.svg'
import icon_5 from './../assets/icon-service/5.svg'
import icon_6 from './../assets/icon-service/6.svg'
import icon_7 from './../assets/icon-service/7.svg'
import icon_8 from './../assets/icon-service/8.svg'
import icon_9 from './../assets/icon-service/9.svg'
import icon_10 from './../assets/icon-service/10.svg'
import icon_11 from './../assets/icon-service/11.svg'
import icon_12 from './../assets/icon-service/12.svg'
import icon_13 from './../assets/icon-service/13.svg'
import icon_14 from './../assets/icon-service/14.svg'
export const services = [
    {
        imageUrl: icon_1,
        title: "Content Marketing",
        description: "Your brand story is at the core of what we do. Through content marketing, we craft compelling narratives that resonate with your audience, driving customer engagement and action."
    },
    {
        imageUrl: icon_2,
        title: "Branding and identity marketing",
        description: "We help you establish a strong brand identity, unique positioning, and compelling messaging that sets you apart in the market"
    },
    {
        imageUrl: icon_3,
        title: "Creative Marketing & Advertsing",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu sed suspendisse in pulvinar. Amet, amet, eleifend lacinia consectetur. In."
    },
    {
        imageUrl: icon_4,
        title: "Social media marketing",
        description: "In today's digital age, we harness the power of social media to build brand awareness, engage your target audience, and effectively promote your products or services."
    },
    {
        imageUrl: icon_5,
        title: "Search Engine Marketing (SEM)",
        description: "With paid advertising on search engines like Google and Bing, we ensure your brand appears prominently when potential customers search for specific keywords, maximizing your online visibility."
    },
    {
        imageUrl: icon_6,
        title: "Search Engine Optimization (SEO)",
        description: "Our SEO experts optimize your online presence, enhancing your organic visibility in search engine results, which translates to increased website traffic and higher rankings"
    },
    {
        imageUrl: icon_7,
        title: "Email Marketing",
        description: "LOur targeted email campaigns are designed to promote your offerings and maintain valuable customer relationships, ensuring repeat business and loyalty"
    },
    {
        imageUrl: icon_8,
        title: "Influencer Marketing",
        description: "We partner with influential individuals on social media to promote your products or services to their engaged audience, building trust and authenticity."
    },
    {
        imageUrl: icon_9,
        title: "Experiential Marketing",
        description: "By creating unique, interactive experiences for consumers, we ensure your brand stays top of mind and creates memorable connections."
    },
    {
        imageUrl: icon_10,
        title: "Event Marketing",
        description: "We take your brand beyond the digital realm, promoting your products or services through in-person events, trade shows, conferences, and sponsorships"
    },
    {
        imageUrl: icon_11,
        title: "Guerrilla Marketing",
        description: "Our creative approach to marketing enables us to make a big impact while staying cost-effective, ensuring maximum returns on your investment."
    },
    {
        imageUrl: icon_12,
        title: "Mobile Marketing",
        description: "We target users on mobile devices through SMS, MMS, mobile apps, or location-based services, capitalizing on mobile's growing influence."
    },
    {
        imageUrl: icon_13,
        title: "Video Production",
        description: "Elevate your brand's storytelling with our video production services, creating engaging promotional videos and social media content that captivate your audience and drive results."
    },
    {
        imageUrl: icon_14,
        title: "Web and App Development",
        description: "Transform your digital presence with our professional web and app development services, ensuring your online assets perform optimally"
    },
];


export const servicesHome = [

    {
        imageUrl: icon_11,
        title: "Guerrilla Marketing",
        description: "Our creative approach to marketing enables us to make a big impact while staying cost-effective, ensuring maximum returns on your investment."
    },
    {
        imageUrl: icon_12,
        title: "Mobile Marketing",
        description: "We target users on mobile devices through SMS, MMS, mobile apps, or location-based services, capitalizing on mobile's growing influence."
    },
    {
        imageUrl: icon_13,
        title: "Video Production",
        description: "Elevate your brand's storytelling with our video production services, creating engaging promotional videos and social media content that captivate your audience and drive results."
    },
    {
        imageUrl: icon_14,
        title: "Web and App Development",
        description: "Transform your digital presence with our professional web and app development services, ensuring your online assets perform optimally"
    },
];

export const our_client = [
    { imageUrl: client_1 },
    { imageUrl: client_2 },
    { imageUrl: client_3 },
    { imageUrl: client_4 },
    { imageUrl: client_5 },
    { imageUrl: client_6 },
    { imageUrl: client_7 },
    { imageUrl: client_8 },
    { imageUrl: client_9 },
    { imageUrl: client_10 },
]