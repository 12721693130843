import './App.css';
import CssBaseline from "@mui/material/CssBaseline";
import NavBar from './components/NavBar';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Footer from './components/foteer/Footer';
import Contact from './pages/Contact';
import Services from './pages/Services';
import { Typography } from '@mui/material';
function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };
  return (
    <div className="App">
      <CssBaseline />
      <NavBar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/services" element={<Services />} />
        {/* <Route path="/service/:id" element={<Service />} /> */}
        <Route path="/contact-us" element={<Contact />} />
      </Routes>
      <Typography style={{ clear: 'both' }} ></Typography>
      <Footer />
    </div>
  );
}

export default App;
