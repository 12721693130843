import { Box, CardMedia, Typography } from '@mui/material'
import React from 'react'
import './hero-contact.css'
import FormLogic from './FormLogic'
import imageUrl from './../../assets/contact-us/hero/2.png'
const HeroContact = () => {
    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: { md: 'row-reverse', xs: 'column' },
                flexWrap: 'wrap',
                mt: '10%',
                alignItems: 'center'
            }} >
                <div className="bg-contact"></div>
                <Box sx={{ width: { md: '50%', xs: '90%' } }} >
                    <CardMedia component={'img'} src={imageUrl} sx={{
                        objectFit: 'contain',
                        height: { md: '35vh', xs: '75vh' },
                        // mt: { md: '7%', xs: 0 }
                    }} />
                </Box>
                <Box sx={{ width: { md: '50%', xs: '90%' } }} >
                    <Typography sx={{ fontSize: { md: '1.7rem', xs: '1.2rem' }, textAlign: "center", fontWeight: 'bold' }}
                        color={' #73F7CF'} >Connect with STASH</Typography>
                    <FormLogic />
                </Box>
                <Box sx={{ zIndex: 999, display: 'flex', flexDirection: 'column', alignItems: 'center', margin: 'auto', mb: 2 }} >
                    <Typography sx={{ color: '#FFF', fontWeight: 'bold', fontSize: { md: '1.6rem', xs: '1rem' }, display: 'flex', alignItems: 'center', gap: '10px' }} ><span>Tel:</span><span> +20111959826</span> || <span>+447429725744</span></Typography>
                    <Typography sx={{ color: '#73F7CF', fontWeight: 'bold', fontSize: { md: '1.5rem', xs: '1rem' } }} >Email: sales@stashmarketing.com</Typography>
                </Box>
            </Box >
        </>
    )
}

export default HeroContact